import styled from 'styled-components';
import Sprint from './components/Sprint';
import Week from './components/Week';


function App() {

	return (
		<Container>
			<Week/>
			<Sprint/>
		</Container>
	);
}

const Container = styled.div`
	text-align: left;
	background-color: rgb(25, 25, 25);
	color: rgba(255, 255, 255, 0.565);
	height: 100%;
	width: 100%;
	
	display: flex;
	flex-direction: column;
	justify-content:  center;

	font-size: 2rem;
`;

export default App;
