import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getShortYear, getSprint } from '../utilities/Date';

const date = new Date();

const Sprint = () => {

	const [sprint] = useState<number | undefined>(getSprint());
	const [year] = useState<number>(getShortYear());

	return (
		<div>
			Sprint <Link href={`https://gitlab.com/groups/tqwi/-/issues?sort=updated_desc&state=opened&milestone_title=S${sprint}.${year}`} target='_blank'>S{sprint}.{year}</Link>
		</div>
	);
};

const Link = styled.a`

	text-decoration: none;
	color: cyan;


	&:visited{
		color: cyan;
	}

	&:hover, :focus{
		text-decoration: underline;
	}
`;

export default Sprint;