import { useState } from 'react';
import { getWeek } from '../utilities/Date';

const Week = () => {

	const [week] = useState<number | undefined>(getWeek());

	return (
		<div>
			Week {week}
		</div>
	);
};

export default Week;