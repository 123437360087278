
const currentDate : any = new Date();
const startDate: any = new Date(currentDate.getUTCFullYear(), 0, 1);


export const getWeek = () : number => {
	const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
	const compensationDays = getCompensationDays(startDate.getUTCDay());
	const result =  Math.ceil((days - compensationDays + 0.001) / 7);
	return result;
};

const lastSprintDay = 3;

export const getSprint = () : number => {
	const week = getWeek();
	const currentWeekDay = currentDate.getUTCDay();

	if(currentWeekDay > lastSprintDay)
		return(week + 1);

	if(currentWeekDay <= lastSprintDay)
		return(week);

	return 0;
};

// to make up for shorter weeks at the start and end of the year we make the following calculation
export const getCompensationDays = (startingDay : number): number => {
	if(startingDay > 4)
		return (7 - startingDay);
	if(startingDay <= 4 )
		return (startingDay);

	return 0;
};	

export const  getShortYear = () : number => {
	return parseInt(currentDate.getUTCFullYear().toString().substring(2,4));
};

